import { formatDate } from "../utils/dates";

export class HebergementsManager {
  hebs = [];

  constructor() {
    this.storeHebergements();
  }

  storeHebergements = () => {
    const hebergements = document.querySelectorAll('[data-product-id]');

    hebergements.forEach(heb => {
      this.hebs.push({
        element: heb,
        productId: parseInt(heb.dataset.productId),
      });
    });
  }

  updateAvailability = (stays, dates) => {
    if (!stays || stays.length === 0) {
      return;
    }



    const formattedDates = {
      start: formatDate(dates.start),
      end: formatDate(dates.end)
    }


    const formattedStays = this.formatStays(stays);

    this.hebs.forEach(heb => {
      const hebStay = formattedStays.find(stay => stay.productId === heb.productId)

      if (hebStay) {
        this.updateHebergementAvailability(heb.element, hebStay, formattedDates);
      }
    });
  }

  formatStays = stays => stays.map(stay => {
    const bookingFees = parseInt(stay.productPrice.bookingFees);
    const price = parseInt(stay.productPrice.price) + bookingFees;

    const discountPrice = stay.productPrice.price > stay.productPrice.finalPrice
      ? parseInt(stay.productPrice.finalPrice) + bookingFees
      : null;

    return {
      price,
      discountPrice,
      lastAvailable: stay.productAvailabilityDate?.stock < 4,
      productId: stay.productId
    }
  });

  updateHebergementAvailability = (el, stay, dates) => {
    const priceValueEl = el.querySelector('.price');

    const dateStartEl = el.querySelector('.dates .start');
    const dateEndEl = el.querySelector('.dates .end');


    console.log(dates);


    if (priceValueEl && dateStartEl && dateEndEl) {
      priceValueEl.textContent = stay.discountPrice ? stay.discountPrice : stay.price;

      dateStartEl.textContent = dates.start;
      dateEndEl.textContent = dates.end;

      el.classList.add('has-values');
      el.classList.remove('no-value');
    }
    
    if (stay.lastAvailable) {
      el.classList.add('is-last');
    }
    
    if (stay.discountPrice) {
      
      el.classList.add('has-offer');
      const initialPriceEl = el.querySelector('.price-no-discount');

      if (initialPriceEl) {
        initialPriceEl.textContent = stay.price;
        console.log(stay.price);
      }
    }
  }

  setLoading = () => {
    this.hebs.forEach(heb => {
      heb.element.classList.remove('has-values');
      heb.element.classList.remove('is-last');
      heb.element.classList.remove('has-offer');
      heb.element.classList.add('no-value');
      heb.element.classList.add('heb-loading');
    });
  }

  stopLoading = () => {
    this.hebs.forEach(heb => {
      heb.element.classList.remove('heb-loading');
    });
  }

  hasProducts = () => this.hebs.length > 0;
}
