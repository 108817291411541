export class StatsManager {
  startTime = 0;
  endTime = 0;

  startRecording = () => {
    this.startTime = Date.now()

    console.log('Started action...')
  }

  endRecordingAndLogDuration = () => {
    this.endTime = Date.now();

    const duration = (this.endTime - this.startTime) / 1000;

    console.log(`Finished action, in ${duration}s.`)
  }
}